<template>
  <div style="height:100%;">
    <div class="layout-horizontal layout-middle padding-top padding-horizontal padding-bottom-small"
         style="height: 28px;">
      <back-button @click="$router.back()"></back-button>
      <div class="font-size-medium margin-left font-weight-bold">打卡管理</div>
    </div>
    <ys-infinite-scroll-view style="height: calc(100% - 28px);" @loadmore="loadMore">
      <div class="layout-vertical padding-horizontal padding-bottom-small padding-top-small ">
        <div class="layout-horizontal margin-bottom-small">
          <div class="btn-start layout-vertical layout-center layout-middle font-size-large font-weight-light" @click="handleStartPunchClick()"> +
            开始打卡
          </div>
          <div class="margin-left-large font-size-medium">
            <div>全部打卡：{{ totalCount }}</div>
            <div class="margin-top-small">正在执行的打卡：{{ countOfStarted }}</div>
            <div class="margin-top-small">已停止的打卡：{{ countOfStopped }}</div>
          </div>
        </div>
        <div class="list-item padding layout-horizontal layout-middle" v-for="punchInstance of punchInstanceList"
             :key="punchInstance.id">
          <div class="layout-inflexible">
            <i class="far fa-clock font-size-large font-color-secondary"></i>
          </div>
          <div class="margin-horizontal layout-flexible">
            <div class="font-size-medium">{{ punchInstance.punch.title }}</div>
            <div class="font-color-secondary">{{ PunchUtils.stringfyPeriod(punchInstance.punch.period) }}</div>
          </div>
          <div class="layout-horizontal layout-inflexible layout-middle">
            <div class="margin-right font-size-small">
              <span v-if="punchInstance.state === BusinessState.STARTED" class="font-color-primary">正在执行</span>
              <span v-else class="font-color-danger">已停止</span>
            </div>
            <el-tooltip v-if="punchInstance.state !== BusinessState.STARTED" content="重新开始" :open-delay="500">
              <ys-button round lighting type="secondary" @click="handleStartPunchClick(punchInstance)">
                <i class="fa fa-play font-size-small font-color-secondary"></i>
              </ys-button>
            </el-tooltip>
            <el-tooltip v-else content="停止打卡" :open-delay="500">
              <ys-button round lighting type="secondary" @click="handleStopPunchClick(punchInstance)">
                <i class="fa fa-stop font-size-small font-color-secondary"></i>
              </ys-button>
            </el-tooltip>
          </div>
        </div>
        <div class="padding font-size-small font-color-placeholder font-align-center">已加载全部</div>
      </div>
    </ys-infinite-scroll-view>
    <punch-picker-dialog ref="punchPickerDialog" :visible.sync="punchPickerDialogVisible" :organization-id="organizationId"
      @pick="handlePunchPicked" :default-selected-ids="defaultSelectedPunchIds"></punch-picker-dialog>
    <delete-dialog
        :visible.sync="stopPunchRemindDialogVisible"
        confirm-button-text="停止"
        :message="`您确定要停止${stoppingPunchInstance ? stoppingPunchInstance.punch.title : '打卡'}吗？`"
        @confirm="handleStopPunchConfirm"
    ></delete-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import PunchUtils from "@/assets/javascript/punch-utils";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import BusinessState from "@/assets/javascript/business-state";
import PunchPickerDialog from "@/components/dialog/PunchPickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";

function doLoadPunchInstanceList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/punch/instance/list/get/by_organization',
    data: {
      pageNum,
      pageSize: 20,
      organizationId: this.organizationId,
      userId: this.userId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.punchInstanceList, res.data.list);
    this.totalCount = res.data.total;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPunchInstanceByPunchId(punchId) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/punch/instance/get/by_punch_id',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      punchId,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.punchInstanceList, [res.data]);
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStartPunch(punchId) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }

  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/punch/start',
    data: {
      punchId,
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    window.eventBus.$emit('start:punch');
    doLoadPunchInstanceByPunchId.bind(this)(punchId);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopPunch(punchInstance) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }

  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/punch/stop',
    data: {
      instanceId: punchInstance.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    punchInstance.state = BusinessState.STOPPED;
    window.eventBus.$emit('stop:punch');
    doLoadPunchInstanceByPunchId.bind(this)(punchInstance.punch.id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PunchInstanceListPanel",
  mixins: [httpapi],
  components: {DeleteDialog, PunchPickerDialog, YsButton, YsInfiniteScrollView, BackButton},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      PunchUtils,
      BusinessState,
      loadingCode: 1,
      busyLoadingCode: 0,
      totalCount: 0,
      countOfStarted: 0,
      countOfStopped: 0,
      punchInstanceList: [],
      pageNum: 1,
      totalPages: 0,

      punchPickerDialogVisible: false,
      stopPunchRemindDialogVisible: false,
      stoppingPunchInstance: null,
    }
  },
  computed: {
    inputParams() {
      return {
        userId: this.userId,
        organizationId: this.organizationId,
      }
    },
    defaultSelectedPunchIds() {
      let list = new Array();
      for (let punchInstance of this.punchInstanceList) {
        if(punchInstance.state === BusinessState.STARTED) {
          list.push(punchInstance.punch.id);
        }
      }
      return list;
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.totalCount = 0;
        this.countOfStarted = 0;
        this.countOfStopped = 0;
        this.punchInstanceList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.userId && this.organizationId) {
          this.loadPunchInstanceList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPunchInstanceList(pageNum = 1) {
      doLoadPunchInstanceList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPunchInstanceList(this.pageNum + 1);
      }
    },
    handleStartPunchClick(punchInstance) {
      if(punchInstance) {
        doStartPunch.bind(this)(punchInstance.punch.id);
      } else {
        this.punchPickerDialogVisible = true;
      }
    },
    handlePunchPicked(punchList) {
      doStartPunch.bind(this)(punchList[0].id);
    },
    handleStopPunchClick(punchInstance) {
      this.stoppingPunchInstance = punchInstance;
      this.stopPunchRemindDialogVisible = true;
    },
    handleStopPunchConfirm() {
      let punchInstance = this.stoppingPunchInstance;
      if(punchInstance) {
        doStopPunch.bind(this)(punchInstance);
      }

    }

  }
}
</script>

<style scoped>

.btn-start {
  width: 180px;
  height: 128px;
  border-radius: 8px;
  border: 1px solid #dadada;
  cursor: pointer;
  background-color: #f1f1f1;
  box-shadow: 0 0 2px #bec0c0;
}

.btn-start:hover {
  box-shadow: 0 0 8px #bec0c0;
}

.list-item {
  width: auto;
}

</style>