<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" :title="title">
    <div>
      <el-collapse-transition>
        <div v-if="selectedPunchList.length > 0">
          <div class="margin-horizontal margin-top">已选择：</div>
          <div class="layout-horizontal layout-wrap margin-horizontal margin-top-small">
            <div class="punch-picked" v-for="punch in selectedPunchList" :key="punch.id"
              @click="handlePunchPickedClick(punch)">
              <span>{{ punch.title }}</span>
              <span class="btn-delete"> <i class="el-icon-close font-size-small"></i></span>
            </div>
          </div>
        </div>
      </el-collapse-transition>
      <ys-infinite-scroll-view @loadmore="loadMore" style="height: 50vh;">
        <div class="padding-horizontal">
          <div class="punch-list-item padding padding-vertical-small layout-middle layout-horizontal"
               v-for="punch in punchList"
               :key="punch.id"
               @click="handlePunchListItemClick(punch)">
            <ys-checker class="layout-inflexible margin-right" :value="punch.__selected"
                        :disable="punch.__disable"></ys-checker>
            <div class="layout-inflexible">
              <i class="far fa-clock font-size-large"></i>
            </div>
            <div class="margin-left-small">
              <div class="font-size-large">{{ punch.title }}</div>
              <div class="font-color-secondary">{{ punch.message }}</div>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button type="primary" lighting class="margin-left" @click="handleConfirmClick">
        <span v-if="selectedPunchList.length > 0">选择({{selectedPunchList.length}}/{{maxSelectedCount}})</span>
        <span v-else>选择</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import YsChecker from "@/components/wedigets/YsChecker";

function doLoadPunchList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/punch/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.punchList, res.data.list);
    this.refreshPunchSelectedState();
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PunchPickerDialog",
  mixins: [httpapi],
  components: {YsChecker, YsInfiniteScrollView, YsButton, YsDialog},
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '选择打卡',
    },
    organizationId: Number,
    defaultSelectedIds: {
      type: String,
      default() {
        return [];
      }
    },
    maxSelectedCount: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,
      isVisible: false,
      selectedPunchList: [],
      punchList: [],
      pageNum: 1,
      totalPages: 0,
    }
  },
  watch: {
    visible: {
      handler() {
        this.selectedPunchList = [];
        this.refreshPunchSelectedState();
        this.isVisible = this.visible;
      },
      immediate: true,
    },
    defaultSelectedIds: {
      handler() {
        this.refreshPunchSelectedState();
      },
      immediate: true,
    },
    organizationId: {
      handler() {
        this.pageNum = 1;
        this.totalPages = 0;
        this.punchList = [];
        if (this.organizationId) {
          this.loadPunchList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPunchList(pageNum = 1) {
      doLoadPunchList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPunchList(this.pageNum + 1);
      }
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick() {
      if(this.selectedPunchList.length == 0) {
        this.$message.error('至少选择一个项目');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedPunchList);
    },
    handlePunchListItemClick(punch) {
      if(punch.__disable) return;
      let selectedIndex = -1;
      for (let n = 0; n < this.selectedPunchList.length; n++) {
        let selectedPunch = this.selectedPunchList[n];
        if (punch.id === selectedPunch.id) {
          selectedIndex = n;
          this.selectedPunchList.splice(selectedIndex, 1);
          break;
        }
      }
      if (selectedIndex < 0) {
        if(this.selectedPunchList.length < this.maxSelectedCount) {
          this.selectedPunchList.push(punch);
        }
      }
      this.refreshPunchSelectedState();
      if(this.maxSelectedCount == 1 && this.selectedPunchList.length == 1) {
        this.handleConfirmClick();
      }
    },
    handlePunchPickedClick(punch) {
      for(let n = this.selectedPunchList.length - 1; n >= 0; n --) {
        if(this.selectedPunchList[n].id === punch.id) {
          this.selectedPunchList.splice(n, 1);
          break;
        }
      }
      this.refreshPunchSelectedState();
    },
    refreshPunchSelectedState() {
      for (let punch of this.punchList) {
        let isDefaultSelected = false;
        for (let defaultSelectedId of this.defaultSelectedIds) {
          if (defaultSelectedId === punch.id) {
            isDefaultSelected = true;
            break;
          }
        }
        if (isDefaultSelected) {
          punch.__selected = true;
          punch.__disable = true;
          continue;
        }

        let isSelected = false;
        for (let selectedPunch of this.selectedPunchList) {
          if (selectedPunch.id === punch.id) {
            isSelected = true;
            break;
          }
        }
        punch.__disable = false;
        if (isSelected) {
          punch.__selected = true;
        } else {
          punch.__selected = false;
        }
      }
    }
  }
}
</script>

<style scoped>

.punch-list-item {
  border-radius: 9px;
  border: 1px solid #dadada;
  margin-top: 3px;
  cursor: pointer;
  min-height: 48px;
}

.punch-list-item:hover {
  opacity: 0.8;
}

.punch-picked {
  background-color: #f091a6;
  color: white;
  padding: 4px 16px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
}

.punch-picked .btn-delete {
  opacity: 0;
}

.punch-picked:hover .btn-delete {
  opacity: 1;
}

</style>