/**
 * 按天打卡
 * @type {number}
 */
const PERIOD_DAYS = 0;

/**
 * 按星期打卡
 * @type {number}
 */
const PERIOD_WEEKS = 1;

const PunchPeriodType = {
    DAYS: PERIOD_DAYS,
    WEEKS: PERIOD_WEEKS
}

export const PunchStatisticalState = {
    /**
     * 空
     */
    EMPTY: 0,

    /**
     * 缺卡
     */
    MISSED: 1,

    /**
     * 全部完成
     */
    FINISHED: 2,

    /**
     * 部分完成
     */
    FINISHED_PARTLY: 3,
}

function defaultPunchPeriod(type) {
    switch (type) {
        case PERIOD_DAYS:
            return {
                type: PERIOD_DAYS,
                intervalDay: 0,
            }
        case PERIOD_WEEKS:
            return {
                type: PERIOD_WEEKS,
                sun: true,
                mon: false,
                tue: false,
                wed: false,
                thu: false,
                fri: false,
                sat: false
            }
    }
    throw new Error('invalid punch period type: ' + type);
}

function defaultPunchItem(sheetId) {
    return {
        id: null,
        title: null,
        optional: true,
        timeRange: null,
        allDay: true,
        sheetIds: sheetId ? [sheetId] : [],
    }
}

function defaultPunch() {
    return {
        title: null,
        message: null,
        image: null,
        questionnaireId: null,
        organizationId: 0,
        punchItems: [],
        period: defaultPunchPeriod(PERIOD_DAYS),
    }
}

function stringfyPeriod(punchPeriod) {
    switch(punchPeriod.type) {
        case PunchPeriodType.DAYS: {
            if(punchPeriod.intervalDay == 0) {
                return "每天";
            } else {
                return `每隔${punchPeriod.intervalDay}天`
            }
            break;
        }
        case PunchPeriodType.WEEKS: {
            let result = "每周";
            let first = true;
            if(punchPeriod.sun) {
                result += "日";
                first = false;
            }
            if(punchPeriod.mon) {
                if(!first) result += "、";
                result += "一"
                first = false;
            }
            if(punchPeriod.tue) {
                if(!first) result += "、";
                result += "二";
                first = false;
            }
            if(punchPeriod.wed) {
                if(!first) result += "、";
                result += "三";
                first = false;
            }
            if(punchPeriod.thu) {
                if(!first) result += "、";
                result += "四"
                first = false;
            }
            if(punchPeriod.fri) {
                if(!first) result += "、";
                result += "五";
                first = false;
            }
            if(punchPeriod.sat) {
                if(!first) result += "、";
                result += "六";
                first = false;
            }
            if(result === "每周日、一、二、三、四、五、六") result = "每天";
            return result;
        }
    }
}

const PunchUtils = {
    PERIOD_DAYS: PERIOD_DAYS,
    PERIOD_WEEKS: PERIOD_WEEKS,
    TITLE_MAX_LENGTH: 80,
    MESSAGE_MAX_LENGTH: 100,
    SHEET_MAX_COUNT: 5,
    PunchPeriodType: PunchPeriodType,
    PunchStatisticalState,
    defaultPunch: defaultPunch,
    defaultPunchPeriod: defaultPunchPeriod,
    defaultPunchItem: defaultPunchItem,
    stringfyPeriod,
}

export default PunchUtils;